<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">待结算列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">待生成结算单</a>
        </span>
      </div>

      <div class="framePage-body">
        <!-- <div
          class="operationControl"
        >
           
          <div class="searchbox" style="margin-bottom:5px">
            <el-button class="bgc-bv" style="margin:0 10px" round @click="generate()">生成结算单</el-button>
          </div>
        </div> -->
        <div style="margin:15px">
          <span>机构名称:{{ compName }}</span>
        </div>
        <div
          class="operationControl flexdc  operationControlWidth"
          style="align-items: flex-start;"
        >
          <div class="searchbox" style="margin-bottom:5px">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">班级编码:</span>
              <el-input
                v-model="searchData.projectCode"
                type="text"
                size="small"
                clearable
                placeholder="请输入班级编码"
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="searchData.projectName"
                type="text"
                size="small"
                clearable
                placeholder="请输入班级名称"
                style="flex:1"
              />
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>

              <el-cascader
                clearable
                filterable
                v-model="searchData.areaId"
                :options="areatreeList"
                :props="propsarea"
                size="small"
              ></el-cascader>
            </div>
            <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </span>
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="generate()"
              >生成结算单</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              row-key="projectId"
              style="width: 100%"
              @selection-change="change"
              stripe
              :header-cell-style="tableHeader"
            >
              <el-table-column
                type="selection"
                width="50px"
                align="center"
                fixed
                :reserve-selection="true"
                :selectable="selectable"
              ></el-table-column>
              <el-table-column
                label="班级编号"
                align="left"
                fixed
                show-overflow-tooltip
                prop="projectCode"
                width="200"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                min-width="260"
              />
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                prop="areaNamePath"
                  min-width="160"
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeNamePath"
                 min-width="160"
              />
              <el-table-column
                label="行业类型"
                align="left"
                :show-overflow-tooltip="true"
                width="120"
              >
                <template slot-scope="scope">{{
                  scope.row.industryNamePath || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="岗位类型"
                align="left"
                :show-overflow-tooltip="true"
                width="120"
              >
                <template slot-scope="scope">{{
                  scope.row.postName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="职业/工种"
                align="left"
                :show-overflow-tooltip="true"
                width="120"
              >
                <template slot-scope="scope">{{
                  scope.row.occupationNamePath || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="培训等级"
                align="left"
                :show-overflow-tooltip="true"
                width="120"
              >
                <template slot-scope="scope">{{
                  scope.row.trainLevelName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="结算单方式"
                align="left"
                :show-overflow-tooltip="true"
                width="120"
              >
                <template slot-scope="scope"><el-switch
                    :width="20"
                    v-model="scope.row.billType"
                    active-value="10"
                    inactive-value="20"
                    active-color="#13ce66"
                    @change="
                      (val) => {
                        handleStu(val, scope.row.projectId);
                      }
                    "
                  ></el-switch>
                  <span>{{scope.row.billType !== '20' ? '线上': '线下'}}</span></template>
              </el-table-column>
              <el-table-column
                label="培训人数"
                align="right"
                show-overflow-tooltip
                prop="peopleNum"
              />

              <el-table-column
                label="课时"
                align="right"
                show-overflow-tooltip
                prop="lessonNum"
              >
                <template slot-scope="scope">{{
                  scope.row.lessonNum || "--"
                }}</template>
              </el-table-column>
              <el-table-column label="公共课" align="right" fixed="right">
                <template slot-scope="scope">{{
                  scope.row.comlessonNum || "--"
                }}</template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "evaluate/statementListIng",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      compName: this.$route.query.compName,
      compId: this.$route.query.compId,
      multipleSelection: [],
      projectIdArr: [],
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      searchData: {
        areaId: "",
        projectCode: "",
        projectName: "",
      },
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      traintypeList: "getTraintype",
    }),
  },
  created() {
    this.getareatree();
  },
  mounted() {},
  methods: {
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        compId: this.$route.query.compId,
      };
      if (this.searchData.projectCode) {
        params.projectCode = this.searchData.projectCode;
      }
      if (this.searchData.projectName) {
        params.projectName = this.searchData.projectName;
      }
      if (this.searchData.areaId) {
        params.areaId = this.searchData.areaId;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/biz/settlement/bill/previewlist",
        params,
        pageNum,
      });
    },

    change(val) {
      //  this.$refs.multipleTable.toggleRowSelection(val);
      this.multipleSelection = val;
    },
    generate() {
      if (!this.multipleSelection.length) {
        this.$message({
          message: "请至少选择一个班级",
          type: "warning",
        });
        return false;
      }
      this.multipleSelection.map((item) => {
        this.projectIdArr.push(item.projectId);
      });
      console.log(this.projectIdArr);
      this.$post("/biz/settlement/bill/check/project", {
        projectIds: this.projectIdArr,
      })
        .then((ret) => {
          if (ret.status == "0") {
            this.$router.push({
              path: "/web/evaluate/generateStatementAccount",
              query: {
                infos:JSON.stringify({
                  compId: this.$route.query.compId,
                  compName: this.$route.query.compName,
                  projectIdArr: this.projectIdArr,
                }),
               
              },
            });
          }
        })
        .catch((err) => {
          this.projectIdArr = [];
          return;
        });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = [...ret.data];
      });
    },
    /**
     * 修改状态
     */
    handleStu(billType, projectId) {
      this.$post("/biz/settlement/bill/changeBillType", {
        billType,
        projectId,
      }).then(res => {
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: "修改成功"
          });
          this.getData(-1);
        }
      });
    },
    selectable(row,index) {
      if(row.billType == '10') {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    compOwnerId: function(val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
};
</script>
